/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

svg {
  // Workaround for the SVG overflow bug in IE10/11 is still required.
  // See https://github.com/twbs/bootstrap/issues/26878
  overflow: hidden;
  vertical-align: unset !important;
}

.txt-box {
  text-align: center;
  border: hidden;
  background-color: rgb(240, 230, 230);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #000 !important;
  cursor: default !important;
}

.label-align {
  margin-left: 10px;
}
.border-redius {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.downloadButton {
  margin-top: 2.5rem !important;
}

#level-dropdown.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
  padding-top: 3px;
}
.ant-select-arrow {
  right: 24px !important;
}
.searchButtonTop {
  margin-top: 40px;
  margin-left: 3px;
}

.ng-Header {
  background-color: #0065b2;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.searchboxAerar {
  background-color: #efefef;
  padding-bottom: 20px;
  -webkit-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.18);
}

.thableLabel {
  background-color: #fff;
  border-right: solid 1px #fff;
}

.rowOne {
  background-color: #f7f7f7;
  border-right: solid 1px #fff;
}

.rowTwo {
  background-color: #efefef;
  border-right: solid 1px #fff;
}

//upload and preview image/files
.images-preview {
  padding: 3px 3px 0px 3px;
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px dashed #d9d9d9;
}

.images-preview .remove-btn {
  position: absolute;
  right: 0;
  border-radius: 0;
}

.images-preview img {
  max-height: 300px;
  object-fit: cover;
}
.images-preview .card-body {
  padding: 1rem;
}
.border-redius {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.disable-dropdown {
  color: rgb(0 0 0) !important;
}

input:read-only {
  color: black;
  background: #f5f5f5;
  // cursor: not-allowed;
}

.font-bold{
  font-weight:bold;
}
